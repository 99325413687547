/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";

// Summary And Greeting Section

const illustration = {
  animated: false // Set to false to use static SVG
};

const greeting = {
  username: "Sahin Kasap",
  title: "Hi all, I'm Sahin",
  subTitle: emoji(
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / .Net Core / Python and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1nKYDTsYDA8099m9DqKh3ANLYxWeiwqL2/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/sahin52",
  linkedin: "https://www.linkedin.com/in/sahinkasap/",
  gmail: "sahinkasap52@gmail.com",
  gitlab: "https://gitlab.com/sahin52",
  // facebook: "https://www.facebook.com/",
  medium: "https://medium.com/@sahinkasap52",
  stackoverflow: "https://stackoverflow.com/users/7219400/sahin",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "C/C++",
      fontAwesomeClassname: "fas fa-c"
    },
    {
      skillName: "C#",
      fontAwesomeClassname: "fas fa-hashtag"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "Go",
      fontAwesomeClassname: "fab fa-golang"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "METU",
      logo: require("./assets/images/metu.png"),
      subHeader: "Bachelor of Science in Computer Engineering",
      duration: "September 2016 - July 2021",
      desc: "One of the highest ranked university in Turkey. Took courses about Software Engineering, Machine Learning, Operating Systems, ...",
      descBullets: [
        "Graduated with a nice result of 3.07/4.0",
        "Educated by Turkey's greatest proffesors, all lessons were in English"
      ]
    }
    // {
    //   schoolName: "Stanford University",
    //   logo: require("./assets/images/stanfordLogo.png"),
    //   subHeader: "Bachelor of Science in Computer Science",
    //   duration: "September 2013 - April 2017",
    //   desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
    //   descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    // }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "ReactJS", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: ".Net Core (C#)",
      progressPercentage: "80%"
    },
    {
      Stack: "Software Design",
      progressPercentage: "70%"
    },
    {
      Stack: "Typescript",
      progressPercentage: "80%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer and Frontend Team Lead",
      company: "Nanodems",
      companylogo: require("./assets/images/nd.png"),
      date: "May 2022 - March 2024",
      desc: "An On-Premise project for security device integrations with web interface and desktop apps. Worked on various parts, integrated a video recorder, reshaped frontend-React project, added web monitoring for cameras, led the frontend team."
    },
    {
      role: "FullStack Developer",
      company: "Siren Bilisim",
      companylogo: require("./assets/images/siren.jfif"),
      date: "August 2021 - May 2022",
      desc: "- A web app, that is used by Securitas Turkey for their internal processes, consisting of 20+ modules. One major module was completely made by me and it was for a service management. There are some other modules that are made by me. All of the project was written with Typescript, React and .Net core(C#) . We used Git on Azure. - Translation of the pages."
      // descBullets: [
      //   "",
      //   ""
      // ]
    },
    {
      role: "Computer Engineer",
      company: "invicta",
      companylogo: require("./assets/images/invicta.png"),
      date: "April 2021 - August 2021",
      desc: "A proxy project. Setting up a video conference server, to make people communicate over proxy. Wrote and updated socket programming codes to let the meeting work. Wrote small Python scripts to handle various jobs, to automatize tasks. Wrote Selenium tests for their web page."
    },
    {
      role: "Software Developer & System Administrator",
      company: "Bigtaus",
      companylogo: require("./assets/images/bigtaus.jfif"),
      date: "May 2020 - December 2020",
      desc: "An access control security company. Development of SyncCore, a Typescript app that synchronizes user data of METU and Teknokent between their REST/SOAP endpoints and Odtupass (Armon access control system serving 50.000+ users). \n- Development of Bigtaus Access App, an Android app, that can read NFC cards and get the information of the user from Armon Api and show, which would be used by security personnels"
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME PROJECTS THAT I HAVE MADE ON MY OWN",
  projects: [
    {
      image: require("./assets/images/hunterdrone.jpg"),
      projectName: "Hunter Drone",
      projectDesc:
        "An autonomous drone eliminating system by Computer Vision (Machine Learning) powered drone, developed using ROS, YOLO, Gazebo with Python and C++. Ranked 5th among 38 projects.",
      footerLink: [
        {
          name: "Visit Main Website",
          url: "https://senior.ceng.metu.edu.tr/2021/hunterdrone/"
        },
        {
          name: "Visit All Senior Projects Website",
          url: "https://senior.ceng.metu.edu.tr/2021/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/alex.png"),
      projectName: "Aliexpress Auto Uploader",
      projectDesc:
        "Auto Product Uploading With The Help Of Selenium With NodeJS-Typescript"
      // footerLink: [
      //   {
      //     name: "Visit Website",
      //     url: "http://nextu.se/"
      //   }
      // ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    // {
    //   title: "Google Assistant Action",
    //   subtitle:
    //     "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
    //   image: require("./assets/images/googleAssistantLogo.webp"),
    //   footerLink: [
    //     {
    //       name: "View Google Assistant Action",
    //       url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
    //     }
    //   ]
    // },

    // {
    //   title: "PWA Web App Developer",
    //   subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
    //   image: require("./assets/images/pwaLogo.webp"),
    //   footerLink: [
    //     {name: "Certification", url: ""},
    //     {
    //       name: "Final Project",
    //       url: "https://pakistan-olx-1.firebaseapp.com/"
    //     }
    //   ]
    // }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "false", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://modernizededucation.blogspot.com/2021/03/solid-principles-sahin-kasap.html",
      title: "SOLID Principles",
      description:
        "It is telling what the SOLID principles are and why they are needed"
    },
    {
      url: "https://modernizededucation.blogspot.com/2020/12/how-to-make-program-to-offboard-drone.html",
      title: "How to Make A Program to Offboard the drone on PX4 gazebo ROS",
      description:
        "PX4 is a project to make robots simulations, and drones are one of the robots that we can simulate. I told how to run a drone with PX4, Gazebo and Ros."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "",
  email_address: "sahinkasap52@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "kasapdev", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
